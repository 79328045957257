import xhr from './xhr/';
/**
 * 方案管理相关的 API
 */
class PlanService {
	// 方案管理首页 获取后台传过来的select配置项
	getPlanSelect(data) {
		return xhr({
			method: 'get',
			url: '/plan/index',
			params: data,
		});
	}

	// 搜索
	getPlanList(data) {
		return xhr({
			method: 'get',
			url: '/plan/list',
			params: data,
		});
	}

	// 搜索
	getAutoPlanList(data) {
		return xhr({
			method: 'get',
			url: '/autoplan/list',
			params: data,
		});
	}

	// 删除方案
	deletePlan(data) {
		return xhr({
			method: 'post',
			url: '/plan/delete',
			params: data,
		});
	}

	// 分配方案
	distribute(data) {
		return xhr({
			method: 'post',
			url: '/plan/distribution',
			params: data,
		});
	}

	// 分配方案
	distributeAll(data) {
		return xhr({
			method: 'post',
			url: '/plan/distribution_all',
			params: data,
		});
	}

	// 获取方案详情
	getDetail(data) {
		return xhr({
			method: 'get',
			url: '/plan/detail',
			params: data,
		});
	}

	// 获取方案详情
	getTeamList(data) {
		return xhr({
			method: 'get',
			url: '/plan/team/list',
			params: data,
		});
	}

	// 提交方案
	submitPlan(data) {
		return xhr({
			method: 'post',
			url: '/plan/submit',
			params: data,
		});
	}

	// 提交调整方案
	submitAdjust(data) {
		return xhr({
			method: 'post',
			url: '/plan/submit_adjust',
			params: data,
		});
	}

	// 审核方案
	checkPlan(data) {
		return xhr({
			method: 'post',
			url: '/plan/check',
			params: data,
		});
	}

	// 申请调整方案
	adjustPlan(data) {
		return xhr({
			method: 'post',
			url: '/plan/adjust',
			params: data,
		});
	}

	// 增加方案
	addPlan(data) {
		return xhr({
			method: 'post',
			url: '/plan/add',
			params: data,
		});
	}

	// 增加方案
	planType(data) {
		return xhr({
			method: 'get',
			url: '/plan/plan_type_list',
			params: data,
		});
	}

	// 获取自动方案数据
	getAutoplanData(data) {
		return xhr({
			method: 'post',
			url: '/autoplan/detail',
			params: data,
		});
	}

	// 获取用户列表
	getUserList(params) {
		return xhr({
			method: 'get',
			url: '/member/query',
			params,
		});
	}
}

// 实例化后导出，全局单例
export default new PlanService();
